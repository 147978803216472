/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { removeMaybe as e } from "../../core/maybe.js";
import { onAbort as s, onAbortOrThrow as t, createResolver as r, createAbortError as i, isPromiseLike as o } from "../../core/promiseUtils.js";
import h from "../../core/Queue.js";
import c from "../../core/ReactiveMap.js";
import { schedule as n } from "../../core/scheduling.js";
import { signal as l } from "../../core/signal.js";
class u {
  constructor(e, s) {
    this.item = e, this.controller = s, this.promise = null;
  }
}
class _ {
  constructor(e) {
    this._schedule = null, this._task = null, this._deferreds = new c(), this._controllers = new c(), this._processingItems = new c(), this._pausedSignal = l(!1), this.concurrency = 1, e.concurrency && (this.concurrency = e.concurrency), this._queue = new h(e.peeker), this.process = e.process;
    const s = e.scheduler;
    e.priority && s && (this._task = s.registerTask(e.priority, this));
  }
  destroy() {
    this.clear(), this._schedule = e(this._schedule), this._task = e(this._task);
  }
  get updating() {
    return !!this._task?.updating || this.running;
  }
  get length() {
    return this._processingItems.size + this._queue.length;
  }
  abort(e) {
    const s = this._controllers.get(e);
    s && s.abort();
  }
  clear() {
    this._queue.clear();
    const e = [];
    this._controllers.forEach(s => e.push(s)), this._controllers.clear(), e.forEach(e => e.abort()), this._processingItems.clear(), this._cancelNext();
  }
  forEach(e) {
    this._deferreds.forEach((s, t) => e(t));
  }
  get(e) {
    const s = this._deferreds.get(e);
    return s ? s.promise : void 0;
  }
  isOngoing(e) {
    return this._processingItems.has(e);
  }
  has(e) {
    return this._deferreds.has(e);
  }
  pause() {
    this._pausedSignal.value || (this._pausedSignal.value = !0, this._cancelNext());
  }
  push(e, o) {
    const h = this.get(e);
    if (h) return h;
    const c = new AbortController();
    let n = null;
    o && (n = s(o, () => c.abort()));
    const l = () => {
        const s = this._processingItems.get(e);
        s && s.controller.abort(), u(), a.reject(i());
      },
      u = () => {
        _.remove(), null != n && n.remove(), this._removeItem(e), this._queue.remove(e), this._scheduleNext();
      },
      _ = t(c.signal, l),
      a = r();
    return this._deferreds.set(e, a), this._controllers.set(e, c), a.promise.then(u, u), this._queue.push(e), this._scheduleNext(), a.promise;
  }
  last() {
    return this._queue.last();
  }
  lastPromise() {
    const e = this.last();
    return e ? this.get(e) : null;
  }
  peek() {
    return this._queue.peek();
  }
  popLast() {
    const e = this._queue.popLast();
    return e && (this._deferreds.get(e)?.reject(i()), this._removeItem(e)), e;
  }
  reset() {
    const e = Array.from(this._processingItems.values());
    this._processingItems.clear();
    for (const s of e) this._queue.push(s.item), s.controller.abort();
    this._scheduleNext();
  }
  resume() {
    this._pausedSignal.value && (this._pausedSignal.value = !1, this._scheduleNext());
  }
  takeAll() {
    const e = [];
    for (; this._queue.length;) e.push(this._queue.pop());
    return this.clear(), e;
  }
  get running() {
    return !this._pausedSignal.value && this._queue.length > 0 && this._processingItems.size < this.concurrency;
  }
  runTask(e) {
    for (; !e.done && this._queue.length > 0 && this._processingItems.size < this.concurrency;) this._process(this._queue.pop()), e.madeProgress();
  }
  _removeItem(e) {
    this._deferreds.delete(e), this._controllers.delete(e), this._processingItems.delete(e);
  }
  _scheduleNext() {
    this._task || this._pausedSignal.value || this._schedule || (this._schedule = n(() => {
      this._schedule = null, this._next();
    }));
  }
  _next() {
    for (; this._queue.length > 0 && this._processingItems.size < this.concurrency;) this._process(this._queue.pop());
  }
  _cancelNext() {
    this._schedule && (this._schedule.remove(), this._schedule = null);
  }
  _processResult(e, s) {
    this._canProcessFulfillment(e) && (this._scheduleNext(), this._deferreds.get(e.item).resolve(s));
  }
  _processError(e, s) {
    this._canProcessFulfillment(e) && (this._scheduleNext(), this._deferreds.get(e.item).reject(s));
  }
  _canProcessFulfillment(e) {
    return !!this._deferreds.get(e.item) && this._processingItems.get(e.item) === e;
  }
  _process(e) {
    if (null == e) return;
    let s;
    const t = new AbortController(),
      r = new u(e, t);
    this._processingItems.set(e, r);
    try {
      s = this.process(e, t.signal);
    } catch (i) {
      this._processError(r, i);
    }
    o(s) ? (r.promise = s, s.then(e => this._processResult(r, e), e => this._processError(r, e))) : this._processResult(r, s);
  }
  get test() {}
}
export { _ as QueueProcessor };